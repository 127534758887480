import React, { Component } from 'react';
import Headroom from 'react-headroom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  Row,
  Col,
  css,
  styled,
  thd,
  withTheme,
  up,
  down,
} from '@smooth-ui/core-sc';
import HeaderLogo from 'theme/BOB-2023-Logo@2x.png';

const CustomHeadroom = styled(Headroom)`
  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
`;

const WelcomeWrapper = styled.div`
  background-color: ${thd('primary', 'darkgrey')};
  margin-bottom: 5px;
`;

const HeaderWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  ${up(
    'lg',
    css`
      height: 135px;
    `
  )};
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: start;
  max-width: 100%;
  width: 100%;

  ${down(
    'lg',
    css`
      padding-left: 0.75rem;
    `
  )};

  ${up(
    'lg',
    css`
      //height: 144px;
    `
  )};

  img {
    height: 74px;
    height: auto;
    width: 100px;

    ${up(
      'lg',
      css`
        //height: 124px;
        //height: auto;
        //width: 169px;
      `
    )};
  }
`;

const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  width: 100%;

  ${up(
    'lg',
    css`
      justify-content: flex-start;
      padding-top: 1.5rem;
    `
  )};
`;

class Header extends Component {
  render() {
    return (
      <header className="site-header">
        {/* Leave the above <header /> here, necessary to contain Headroom */}
        {/* Otherwise Headroom will wrap all sibling elements */}
        <CustomHeadroom disableInlineStyles downTolerance={10}>
          <HeaderWrapper className="header-wrapper">
            <WelcomeWrapper>{this.props.welcome}</WelcomeWrapper>
            <Grid>
              <Row>
                <Col xs={4} lg={1}>
                  <LogoContainer>
                    <Link to="/">
                      <img src={HeaderLogo} alt="Incremental" />
                    </Link>
                  </LogoContainer>
                </Col>
                <Col xs={8} lg={11}>
                  <HeaderRightContainer>
                    {this.props.children}
                  </HeaderRightContainer>
                </Col>
              </Row>
            </Grid>
          </HeaderWrapper>
        </CustomHeadroom>
      </header>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
  welcome: PropTypes.object.isRequired,
};

Header.defaultProps = {
  children: undefined,
  theme: undefined,
};

export default withTheme(Header);
